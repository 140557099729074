import React from "react";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";
import { CHECK_ICON, SD_VIDEO_IMG, SERVICES_DETAILS01 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { Layout } from "../../layouts/Layout";

const HabitCorrectionPage = () => {
  return (
    <Layout
      breadcrumb={"Fitness In Children"}
      title={"Fitness In Children"}
      backgroundImageUrl={"../../photo/aa40.jpg"}
    >
      {/* services-details-area */}
      <ServicesDetailsWrapper hideContact hideTitle>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src="../photo/aa39.jpg" alt="" />
          </div>

          <div className="services-details-content">
            <h2 className="title">Fitness In Children</h2>
            <div style={{ marginBottom: "14px" }}>
              Fitness is a cornerstone of child health, offering numerous
              benefits beyond physical well-being. Regular exercise plays a
              vital role in managing childhood obesity, a growing concern
              worldwide. By increasing energy expenditure and building muscle
              mass, physical activity helps children achieve and maintain a
              healthy weight, reducing their risk of associated health
              complications.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Beyond weight management, fitness is a valuable tool in addressing
              attention deficit hyperactivity disorder (ADHD). Exercise has been
              shown to improve focus, attention, and behavior in children with
              ADHD. The physical exertion helps channel excess energy, leading
              to reduced impulsivity and hyperactivity. Regular physical
              activity can also enhance cognitive function and academic
              performance.
            </div>

            <div style={{ marginBottom: "14px" }}>
              The impact of fitness extends to mental health. Exercise releases
              endorphins, natural mood elevators that combat anxiety,
              depression, and stress. Regular physical activity promotes better
              sleep, which is essential for emotional regulation and overall
              well-being. Children who engage in regular exercise often exhibit
              improved self-esteem and resilience.
            </div>

            <div style={{ marginBottom: "14px" }}>
              For children with chronic conditions like asthma, diabetes, or
              arthritis, fitness is particularly beneficial. Physical activity
              can improve lung function, blood sugar control, and joint
              flexibility. Regular exercise enhances overall quality of life and
              independence for these children, empowering them to participate in
              various activities.
            </div>

            <div style={{ marginBottom: "14px" }}>
              In conclusion, fitness is a powerful treatment modality for
              children. It addresses a wide range of health concerns, from
              obesity and ADHD to mental health and chronic conditions. By
              incorporating physical activity into children's lives, we can
              significantly improve their overall health, well-being, and
              quality of life.
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    </Layout>
  );
};

export default HabitCorrectionPage;
