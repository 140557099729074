import React from "react";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";
import { CHECK_ICON, SD_VIDEO_IMG, SERVICES_DETAILS01 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { Layout } from "../../layouts/Layout";

const ADHDvaluation = () => {
  return (
    <Layout
      breadcrumb={"Common Illness in Children"}
      title={"Common Illness in Children"}
      backgroundImageUrl={"../../photo/aa44.jpg"}
    >
      {/* services-details-area */}
      <ServicesDetailsWrapper hideContact hideTitle>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src="../photo/aa43.jpg" alt="" />
          </div>

          <div className="services-details-content">
            <h2 className="title">Common Illness in Children</h2>
            <div style={{ marginBottom: "14px" }}>
              Childhood is often accompanied by a series of minor illnesses that
              are part of the growing process. Understanding common childhood
              illnesses can help parents recognize symptoms and provide
              appropriate care. Some of the most prevalent childhood illnesses
              include:
            </div>

            <div style={{ marginBottom: "14px" }}>
              <b>The Common Cold:</b> Caused by a virus, the common cold is
              characterized by runny nose, congestion, cough, and sneezing.
              While uncomfortable, it typically resolves on its own within a
              week.
            </div>

            <div style={{ marginBottom: "14px" }}>
              <b>Ear Infections:</b> Often associated with colds or allergies,
              ear infections can cause ear pain, fever, and irritability.
              Antibiotics may be necessary in some cases.
            </div>

            <div style={{ marginBottom: "14px" }}>
              <b>Sore Throat:</b> Sore throats can be caused by viruses or
              bacteria. Viral sore throats usually resolve on their own, while
              bacterial infections may require antibiotics.
            </div>

            <div style={{ marginBottom: "14px" }}>
              <b>Stomach Flu (Gastroenteritis):</b> This viral infection leads
              to diarrhea, vomiting, and abdominal cramps. It's essential to
              prevent dehydration by offering plenty of fluids.
            </div>

            <div style={{ marginBottom: "14px" }}>
              <b>Fever:</b> A fever is the body's natural response to infection.
              While it's often a cause for concern, mild fevers can be managed
              with over-the-counter medications and plenty of rest.
            </div>

            <div style={{ marginBottom: "14px" }}>
              <b>Skin Rashes:</b> Various skin rashes, such as eczema,
              chickenpox, or measles, can occur in children. Identifying the
              specific rash is crucial for appropriate treatment.
            </div>

            <div style={{ marginBottom: "14px" }}>
              It's important to consult a pediatrician if your child's symptoms
              worsen, if they experience persistent fever, or if you have any
              concerns about their health.
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    </Layout>
  );
};

export default ADHDvaluation;
