import React from "react";
import { Layout } from "../../layouts/Layout";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";
import { CHECK_ICON, SD_VIDEO_IMG, SERVICES_DETAILS01 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";

const CrownsPage = () => {
  return (
    <Layout
      breadcrumb={"Child Health"}
      title={"Child Health"}
      backgroundImageUrl={"../../photo/aa37.avif"}
    >
      {/* services-details-area */}
      <ServicesDetailsWrapper hideContact hideTitle>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src="../photo/aa38.jpg" alt="" />
          </div>

          <div className="services-details-content">
            <h2 className="title">Child Health</h2>
            <div style={{ marginBottom: "14px" }}>
              Child health is a critical aspect of overall well-being. It
              encompasses a wide range of physical, mental, and emotional
              developmental stages. Ensuring optimal child health requires a
              comprehensive approach that addresses preventive care, early
              diagnosis, and effective treatment.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Preventive care is fundamental to child health. This includes
              routine check-ups, vaccinations, and immunization to protect
              children from preventable diseases. Good nutrition, regular
              exercise, and adequate sleep are essential for building a strong
              immune system and promoting overall growth and development.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Early diagnosis is crucial in managing childhood illnesses.
              Regular check-ups allow healthcare providers to identify potential
              health issues early on, when treatment is most effective. Common
              childhood illnesses such as fever, cough, cold, and diarrhea often
              require supportive care and may necessitate medical intervention.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Chronic conditions like asthma, allergies, and diabetes require
              specialized care and management. Developing a comprehensive
              treatment plan involves monitoring symptoms, medication
              management, and lifestyle modifications. Regular follow-ups and
              education are essential for managing these conditions effectively.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Mental and emotional well-being is equally important for child
              health. Children may experience a range of emotional challenges,
              such as anxiety, depression, or behavioral issues. Early
              intervention and appropriate support can help children cope with
              these challenges and develop healthy coping mechanisms.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Child health is a collaborative effort involving parents,
              healthcare providers, and the community. By prioritizing child
              health and well-being, we can create a healthier future for
              generations to come.
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    </Layout>
  );
};

export default CrownsPage;
