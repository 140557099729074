import React from "react";
import { Layout } from "../../layouts/Layout";
import { BlogPageWrapper } from "../../components/BlogAreas/BlogPageWrapper";
import { Link } from "react-router-dom";
import {
  BLOG_AVATAR01,
  BLOG_DETAILS01,
  BLOG_DETAILS02,
  CHECK_ICON,
} from "../../lib/assets";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { BlogAuthorInfo } from "../../components/BlogAreas/BlogAuthorInfo";
import { BlogComments } from "../../components/BlogAreas/BlogComments";
import { BlogCommentForm } from "../../components/BlogAreas/BlogCommentForm";

const BlogDetailsPage = () => {
  return (
    <Layout
      breadcrumb={"Blog Details"}
      title={"Blog Details"}
      backgroundImageUrl={"../../photo/aa29.jpg"}
    >
      <BlogPageWrapper>
        {/* image */}
        <div style={{ marginTop: "50px" }} className="blog-details-thumb">
          <img style={{ width: "100%" }} src="../photo/aa28.jpg" alt="" />
        </div>

        {/* content */}
        <div className="blog-details-content">
          <h2 className="title">Managing Childhood Asthma: Tips For Parents</h2>

          <div className="blog-meta-three">
            <ul className="list-wrap">
              <li>
                <i className="far fa-calendar"></i>20 Feb, 2023
              </li>
              <li>
                <i className="fas fa-tags"></i>
                Dr. Sanjeev Kumar
              </li>
              <li>
                <i className="flaticon-speech-bubble"></i>
                <Link to="/blog-details">05 Comments</Link>
              </li>
            </ul>
          </div>

          <p>
            Managing childhood asthma involves creating a safe and supportive
            environment for your child to minimize triggers and manage symptoms
            effectively. Start by working closely with your child's healthcare
            provider to develop a personalized asthma action plan. This plan
            should outline daily management strategies, such as the use of
            preventive inhalers, and provide clear instructions on how to
            recognize and handle asthma attacks. Keeping track of your child's
            symptoms and medication use in an asthma diary can help in
            identifying patterns and triggers, which may include allergens like
            dust mites, pet dander, pollen, and environmental factors such as
            smoke or pollution. Educating yourself and your child about asthma,
            its triggers, and how to use inhalers properly is crucial.
            Additionally, maintaining regular follow-up appointments with the
            healthcare provider ensures that the asthma action plan remains
            effective and up-to-date.
          </p>
          <p>
            Creating a supportive home environment is equally important. Ensure
            that your home is clean and free from asthma triggers by regularly
            dusting, vacuuming, and using air purifiers. Encourage your child to
            stay active while monitoring their physical activity to avoid
            overexertion. Inform teachers, caregivers, and friends about your
            child's condition and provide them with a copy of the asthma action
            plan to ensure they know how to respond in case of an emergency.
            Encourage your child to express how they feel and discuss their
            symptoms openly, fostering a sense of control over their condition.
            Emotional support and reassurance from parents play a vital role in
            helping children manage their asthma effectively and lead a normal,
            active life.
          </p>
        </div>

        <div style={{ marginTop: "50px" }} className="blog-details-thumb">
          <img style={{ width: "100%" }} src="../photo/aa27.avif" alt="" />
        </div>

        {/* content */}
        <div className="blog-details-content">
          <h2 className="title">
            Recognizing And Addressing ADHD Symptoms Early
          </h2>

          <div className="blog-meta-three">
            <ul className="list-wrap">
              <li>
                <i className="far fa-calendar"></i>22 Jan, 2023
              </li>
              <li>
                <i className="fas fa-tags"></i>
                Dr. Sanjeev Kumar
              </li>
              <li>
                <i className="flaticon-speech-bubble"></i>
                <Link to="/blog-details">15 Comments</Link>
              </li>
            </ul>
          </div>

          <p>
            Recognizing and addressing ADHD symptoms early in children is
            crucial for their long-term development and well-being. Early signs
            of ADHD often include difficulties with attention, hyperactivity,
            and impulsivity that are inconsistent with the child’s age and
            developmental level. Parents and educators should be vigilant in
            observing behaviors such as frequent forgetfulness, inability to
            stay focused on tasks, excessive talking, fidgeting, and difficulty
            in following instructions. These symptoms can significantly impact a
            child’s academic performance, social interactions, and self-esteem.
            Early intervention, including professional assessment and diagnosis
            by a healthcare provider, can lead to effective management
            strategies that help mitigate these impacts and support the child's
            growth.
          </p>
          <p>
            Addressing ADHD symptoms early involves a combination of behavioral
            therapies, educational interventions, and, when necessary,
            medication. Behavioral therapy can help children develop coping
            strategies and improve their organizational skills, while
            educational interventions can provide tailored support to enhance
            learning experiences. Collaboration between parents, teachers, and
            healthcare providers is essential in creating a comprehensive
            support system for the child. Parents can also benefit from training
            programs that teach effective parenting techniques tailored to
            managing ADHD. By recognizing and addressing ADHD symptoms early,
            parents and educators can help children build a strong foundation
            for success, improving their overall quality of life and future
            prospects.
          </p>
        </div>

        <div style={{ marginTop: "50px" }} className="blog-details-thumb">
          <img style={{ width: "100%" }} src="../photo/aa26.avif" alt="" />
        </div>

        {/* content */}
        <div className="blog-details-content">
          <h2 className="title">
            The Importance Of Regular Pediatric Check-Ups
          </h2>

          <div className="blog-meta-three">
            <ul className="list-wrap">
              <li>
                <i className="far fa-calendar"></i>12 Aug, 2023
              </li>
              <li>
                <i className="fas fa-tags"></i>
                Dr. Sanjeev Kumar
              </li>
              <li>
                <i className="flaticon-speech-bubble"></i>
                <Link to="/blog-details">55 Comments</Link>
              </li>
            </ul>
          </div>

          <p>
            Regular pediatric check-ups are essential for monitoring and
            ensuring the healthy development of children from infancy through
            adolescence. These routine visits allow healthcare providers to
            track a child's growth, development, and overall health, catching
            potential issues early before they become serious problems. During
            check-ups, pediatricians assess physical health, including growth
            metrics like weight, height, and head circumference, and perform
            necessary vaccinations to protect against various illnesses. They
            also screen for developmental milestones and provide guidance on
            nutrition, sleep, and behavior, offering parents an opportunity to
            discuss any concerns they might have. Early detection of health
            issues through regular check-ups can lead to more effective
            treatments and better health outcomes, preventing complications and
            promoting long-term well-being.
          </p>
          <p>
            In addition to physical health, regular pediatric check-ups play a
            crucial role in monitoring mental and emotional development.
            Pediatricians can identify early signs of behavioral issues,
            developmental disorders, and mental health conditions, providing
            referrals to specialists if needed. They also offer valuable advice
            on promoting healthy lifestyle habits, such as proper diet,
            exercise, and sleep hygiene, which are foundational to a child's
            overall health. Regular check-ups foster a strong relationship
            between the family and healthcare provider, ensuring continuity of
            care and personalized attention to the child's needs. By
            prioritizing these visits, parents can ensure their children receive
            comprehensive care that supports their physical, mental, and
            emotional development throughout their formative years.
          </p>
        </div>
      </BlogPageWrapper>
    </Layout>
  );
};

export default BlogDetailsPage;
