import React from "react";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";
import { CHECK_ICON, SD_VIDEO_IMG, SERVICES_DETAILS01 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { Layout } from "../../layouts/Layout";

const ImmunizationsPage = () => {
  return (
    <Layout
      breadcrumb={"Immunizations"}
      title={"Immunizations"}
      backgroundImageUrl={"../../photo/aa5.jpg"}
    >
      {/* services-details-area */}
      <ServicesDetailsWrapper hideContact hideTitle>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src="../photo/aa6.jpg" alt="" />
          </div>

          <div className="services-details-content">
            <h2 className="title">Immunizations</h2>
            <p>
              Immunizations, also known as vaccinations, play a vital role in
              safeguarding children's health by providing protection against a
              wide range of infectious diseases. These vaccines contain weakened
              or inactivated forms of pathogens, or pieces of the pathogen like
              proteins, which stimulate the child's immune system to recognize
              and fight the actual infections if they are encountered in the
              future. The widespread use of immunizations has led to the
              significant reduction, and in some cases, eradication of diseases
              such as polio, measles, and smallpox, which once caused severe
              illness and death in children.
            </p>
            <p>
              One of the primary benefits of immunizations is the concept of
              herd immunity. When a significant portion of the population is
              vaccinated, the spread of contagious diseases is minimized,
              protecting those who cannot be vaccinated, such as infants,
              pregnant women, or individuals with certain medical conditions.
              This collective immunity is crucial in preventing outbreaks and
              maintaining public health. Pediatricians play a critical role in
              educating parents about the importance of following the
              recommended vaccination schedule to ensure their children, and the
              community at large, are protected.
            </p>
            <p>
              Immunization schedules are carefully developed based on extensive
              research to provide immunity at the most effective times in a
              child's development. These schedules are designed to protect
              children when they are most vulnerable to specific diseases. For
              example, the measles, mumps, and rubella (MMR) vaccine is
              typically administered in two doses, first at 12-15 months of age
              and again at 4-6 years old. Adhering to these schedules ensures
              that children receive the full benefits of each vaccine, providing
              optimal protection throughout their early years and beyond.
            </p>
            <p>
              Despite the proven effectiveness and safety of vaccines, some
              parents have concerns about potential side effects. Most side
              effects are mild and temporary, such as soreness at the injection
              site or a low-grade fever. Serious side effects are extremely
              rare, and the risks of not vaccinating far outweigh these
              potential issues. Pediatricians are equipped to address these
              concerns, provide accurate information, and reassure parents about
              the safety protocols and extensive testing that vaccines undergo
              before being approved for use.
            </p>
            <p>
              In conclusion, immunizations are a cornerstone of pediatric
              healthcare, offering critical protection against many serious and
              potentially life-threatening diseases. By following the
              recommended vaccination schedules, parents can help ensure their
              children are safeguarded against these infections, contributing to
              their overall health and well-being. Furthermore, immunizations
              play a vital role in maintaining public health through herd
              immunity, protecting vulnerable populations who cannot be
              vaccinated. Through continued education and adherence to
              vaccination programs, we can work towards a future free from many
              preventable diseases.
            </p>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    </Layout>
  );
};

export default ImmunizationsPage;
