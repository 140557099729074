import React from "react";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";
import { CHECK_ICON, SD_VIDEO_IMG, SERVICES_DETAILS01 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { Layout } from "../../layouts/Layout";

const BehavioralAndMentalHealthServices = () => {
  return (
    <Layout
      breadcrumb={"Travel Medicine"}
      title={"Travel Medicine"}
      backgroundImageUrl={"../../photo/aa48.jpg"}
    >
      {/* services-details-area */}
      <ServicesDetailsWrapper hideContact hideTitle>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src="../photo/aa47.jpg" alt="" />
          </div>

          <div className="services-details-content">
            <h2 className="title">Travel Medicine</h2>
            <div style={{ marginBottom: "14px" }}>
              Travel medicine for children is a specialized area focused on
              protecting the health and well-being of young travelers. Children
              have unique health needs and vulnerabilities compared to adults,
              requiring specific considerations for safe and enjoyable journeys.
              A comprehensive travel medicine consultation for children involves
              assessing their age, health status, vaccination history, and the
              planned itinerary to identify potential risks.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Vaccinations are a cornerstone of child travel health. Many
              countries require specific immunizations for entry, and some
              destinations may have increased risks of vaccine-preventable
              diseases. It's essential to adhere to recommended vaccination
              schedules and ensure timely administration of necessary vaccines.
              Additionally, children may require additional vaccinations based
              on their age and the specific travel destinations.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Travel-related illnesses can pose significant challenges for
              children. Common pediatric travel ailments include diarrhea,
              dehydration, jet lag, motion sickness, and insect bites.
              Preventive measures such as hand hygiene, safe food and water
              practices, and appropriate clothing can help reduce the risk of
              these illnesses. Parents should be prepared to manage common
              childhood illnesses while traveling.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Altitude sickness can affect children traveling to high-altitude
              destinations. Symptoms may include headache, nausea, fatigue, and
              difficulty sleeping. Gradual ascent and acclimatization are
              crucial for preventing altitude sickness. It's essential to
              consult a healthcare provider for specific guidance based on the
              child's age and the planned itinerary.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Chronic medical conditions in children require special attention
              during travel. Conditions like asthma, diabetes, allergies, or
              epilepsy may need additional planning and preparation. Adequate
              medication supplies, emergency contacts, and understanding local
              healthcare resources are essential for managing these conditions
              while traveling.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Travel medicine for children emphasizes a proactive approach to
              health and safety. By consulting with a travel medicine
              specialist, parents can obtain expert advice, necessary
              vaccinations, and preventive measures to ensure their child's
              well-being throughout the journey. Safe and healthy travel
              experiences contribute to the overall growth and development of
              children.
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    </Layout>
  );
};

export default BehavioralAndMentalHealthServices;
