import React from "react";
import { QUOTE02 } from "../../lib/assets";
import H4_TESTIMONIAL_SHAPE02 from "../../assets/img/images/aa3.png";
import H4_TESTIMONIAL_SHAPE01 from "../../assets/img/images/aa4.png";
import H4_TESTIMONIAL_IMG_SHAPE from "../../assets/img/images/aa5.png";
import H4_TESTIMONIAL_IMG from "../../assets/img/images/aa6.png";
import {
  SlickSlider,
  SlickSliderArrowNext,
  SlickSliderArrowPrev,
} from "../SlickSlider/SlickSlider";

export const BannerFour = () => {
  const slick_settings_brands = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: true,
    prevArrow: (
      <SlickSliderArrowPrev classNameWrapper="testimonial-nav-four testimonial-nav-four-prev" />
    ),
    nextArrow: (
      <SlickSliderArrowNext classNameWrapper="testimonial-nav-four testimonial-nav-four-next" />
    ),
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section style={{ padding: "0px   " }} className="testimonial-area-four ">
      {/* <div class="testimonial-nav-four testimonial-nav-four-prev">
        <button type="button" class="slick-prev slick-arrow">
          <i class="flaticon-right-arrow"></i>
        </button>
      </div> */}
      <SlickSlider settings={slick_settings_brands}>
        <div className="testimonial-item-four">
          <img src="../photo/Banner.png" alt="" />
        </div>
        <div className="testimonial-item-four">
          <img src="../photo/banner-2.png" alt="" />
        </div>
        <div className="testimonial-item-four">
          <img src="../photo/banner-3.png" alt="" />
        </div>
      </SlickSlider>
    </section>
  );
};
