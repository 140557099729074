import React from "react";
import { Layout } from "../../layouts/Layout";
import { BrandSix } from "../../components/Brand/BrandSix";
import { AboutEight } from "../../components/About/AboutEight";
import { ChooseAreaThree } from "../../components/ChooseAreas/ChooseAreaThree";
import { TeamAreaOne } from "../../components/TeamAreas/TeamAreaOne";
import { TestimonialAreaThree } from "../../components/TestimonialAreas/TestimonialAreaThree";
import { ServicesThree } from "../../components/Services/ServicesThree";
import { CounterAreaThree } from "../../components/CounterAreas/CounterAreaThree";
import { TeamAreaTwo } from "../../components/TeamAreas/TeamAreaTwo";
import { ChooseAreaOne } from "../../components/ChooseAreas/ChooseAreaOne";
import { ServicesTwo } from "../../components/Services/ServicesTwo";

const AboutPageTwo = () => {
  return (
    <Layout
      breadcrumb={"About"}
      title={"About us"}
      backgroundImageUrl={"../../photo/aa36.avif"}
    >
      {/* about-area */}

      <div style={{ marginTop: "120px", marginBottom: "-120px" }}>
        <CounterAreaThree />
      </div>
      <AboutEight />

      {/* choose-area */}

      <ServicesTwo />

      {/* team-area */}

      {/* testimonial-area */}
      <TestimonialAreaThree
        className={"testimonial-area-three testimonial-area-six pt-120"}
      />
      <div style={{ marginTop: "120px" }}></div>

      {/* brand-area */}
      {/* <BrandSix /> */}
    </Layout>
  );
};

export default AboutPageTwo;
