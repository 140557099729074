import React from "react";
import { H4_ABOUT_IMG01 } from "../../lib/assets";
import { Odometer } from "../Odometer/Odometer";
import H4_ABOUT_IMG02 from "../../assets/img/images/aa12.jpg";
import H4_ABOUT_SHAPE from "../../assets/img/images/aa13.png";
import ABOUT_AUTHOR from "../../assets/img/images/aa16.png";
import { Link } from "react-router-dom";

export const AboutFive = () => {
  return (
    <section className="about-area-five">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-5 col-lg-6 col-md-8">
            <div className="about-img-wrap-five">
              <img src="../photo/aa53.png" alt="" />
              {/* <img src={H4_ABOUT_IMG02} alt="" data-parallax='{"y" : 100 }' /> */}
              <div className="experience-wrap">
                <h2 className="title">
                  15 <span>Years Of Experience</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-xl-7 col-lg-6">
            <div className="about-content-five">
              <div className="section-title-two mb-30 tg-heading-subheading animation-style2">
                <span className="sub-title tg-element-title">Pediatrician</span>
                <h2 className="title tg-element-title">
                  About Dr. Sanjeev Kumar
                </h2>
              </div>
              <p>
                Dr. Sanjeev Kumar practices Paediatrician in Araria and has 13
                years of experience in this field. He has completed his MBBS.
                Dr. Sanjeev Kumar has gained the confidence of patients and is a
                popular Paediatrician expert in Araria who performs treatment
                and procedures for various health issues.
              </p>
              <div className="about-content-bottom">
                <div className="about-list">
                  <ul className="list-wrap">
                    <li>
                      <i className="fas fa-arrow-right"></i>Senior resident,
                      consultant, DCBH
                    </li>
                    <li>
                      <i className="fas fa-arrow-right"></i>Member, IAP and NNF
                    </li>
                    <li>
                      <i className="fas fa-arrow-right"></i>PGPN, Boston
                      University graduate
                    </li>
                    <li>
                      <i className="fas fa-arrow-right"></i>Private clinic,
                      Rampur Nursing Home
                    </li>
                  </ul>
                </div>
                <div className="about-success-wrap">
                  <div className="services-btn">
                    <Link to="/about" className="btn">
                      Know More
                    </Link>
                  </div>
                  <div className="about-author-info">
                    <div className="thumb">
                      <img
                        style={{ padding: "10px" }}
                        src={ABOUT_AUTHOR}
                        alt=""
                      />
                    </div>

                    <div className="content">
                      <h2 className="title">Contact Us</h2>
                      <span>8809076364</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-shape-five">
        <img src={H4_ABOUT_SHAPE} alt="" />
      </div>
    </section>
  );
};
