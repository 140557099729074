import React from "react";
import { Link } from "react-router-dom";
import { INNER_ABOUT_IMG03, INNER_ABOUT_IMG04 } from "../../lib/assets";
import ABOUT_AUTHOR from "../../assets/img/images/aa16.png";

export const AboutEight = () => {
  return (
    <section className="about-area-eight pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="about-img-eight">
              <img src="../photo/aa54.png" alt="" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content-eight">
              <div className="section-title-two mb-30">
                <span className="sub-title">Get To Know More</span>
                <h2 className="title">
                  Dr. Sanjeev Kumar: Expert Pediatrician in Forbesganj, Bihar
                </h2>
              </div>
              <p>
                Dr. Sanjeev Kumar, a pediatrician in Forbesganj, Bihar, has over
                15 years of experience. Specializing in neonatology and
                pediatric nutrition, he practices at Rampur Nursing Home,
                providing personalized child healthcare with advanced medical
                insights and specialized training for newborns.
              </p>
              <div className="about-content-inner">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <i className="flaticon-business-presentation"></i>
                    </div>
                    <div className="content">
                      <h4 className="title">Advanced Pediatric Care</h4>
                      <p>
                        Dr. Kumar provides expert neonatal and pediatric care.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="flaticon-investment"></i>
                    </div>
                    <div className="content">
                      <h4 className="title">Experienced Specialist</h4>
                      <p>
                        Over 15 years in pediatric nutrition and neonatology.
                      </p>
                    </div>
                  </li>
                </ul>

                <div style={{ width: "224px" }} className="right-slide-img">
                  <img src="../photo/aa35.jpg" alt="" />
                </div>
              </div>

              <div className="about-content-bottom">
                <div className="services-btn">
                  <Link to="/about" className="btn">
                    Know More
                  </Link>
                </div>

                <div className="about-author-info">
                  <div className="thumb">
                    <img
                      style={{ padding: "10px" }}
                      src={ABOUT_AUTHOR}
                      alt=""
                    />
                  </div>

                  <div className="content">
                    <h2 className="title">Contact Us</h2>
                    <span>8809076364</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
