import React from "react";
import { CONTACT_IMG } from "../../lib/assets";

export const ContactAreaInner = () => {
  return (
    <section className="inner-contact-area pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="inner-contact-img">
              <img src="../photo/aa30.jpg" alt="" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="inner-contact-info">
              <div className="contact-info-item">
                <div className="section-title-two mb-30">
                  <span className="sub-title">Contact Info</span>
                  <h2 className="title">
                    Dr. Sanjeev Kumar, Forbesganj pediatrician expert.
                  </h2>
                </div>
                <p>
                  Dr. Sanjeev Kumar, a pediatrician in Forbesganj, Bihar, has
                  over 15 years of experience. Specializing in neonatology and
                  pediatric nutrition, he practices at Rampur Nursing Home,
                  providing personalized child healthcare with advanced medical
                  expertise and specialized training for newborns.
                </p>
                <h5 style={{ marginTop: "20px" }} className="title-two">
                  Our Hospital Address
                </h5>
                <ul className="list-wrap">
                  <li>
                    Chandra Medical Hall Hospital Road, Forbesganj, Araria -
                    854318
                  </li>
                  <li>8809076364</li>
                  <li>skdas752@gmail.com</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
