import React from "react";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";
import { CHECK_ICON, SD_VIDEO_IMG, SERVICES_DETAILS01 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { Layout } from "../../layouts/Layout";

const AsthmaManagement = () => {
  return (
    <Layout
      breadcrumb={"Injury In Children"}
      title={"Injury In Children"}
      backgroundImageUrl={"../../photo/aa42.jpg"}
    >
      {/* services-details-area */}
      <ServicesDetailsWrapper hideContact hideTitle>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src="../photo/aa41.jpg" alt="" />
          </div>

          <div className="services-details-content">
            <h2 className="title">Injury In Children</h2>
            <div style={{ marginBottom: "14px" }}>
              Injuries are a common occurrence in childhood, often resulting
              from a child's natural curiosity and exploratory nature. While
              accidents can't always be prevented, understanding common injury
              types and taking preventive measures can significantly reduce the
              risk of serious harm.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Common childhood injuries include falls, burns, drowning,
              poisoning, and head injuries. Many of these injuries occur in the
              home environment, making it crucial to childproof your living
              space. Proper installation of safety gates, cabinet locks, and
              outlet covers can prevent numerous accidents. Supervision is also
              essential, especially for young children.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Road safety is another critical area for injury prevention.
              Teaching children pedestrian safety rules, the importance of
              wearing helmets while biking or skating, and using appropriate car
              restraints are essential. It's also crucial to set a good example
              by always buckling up and obeying traffic laws.
            </div>

            <div style={{ marginBottom: "14px" }}>
              In case of an injury, prompt and appropriate first aid is crucial.
              Knowing basic first aid techniques can help minimize the severity
              of an injury and prevent complications. It's essential to seek
              medical attention for any serious injury or if you are unsure how
              to proceed.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Preventing childhood injuries requires a multi-faceted approach
              involving parents, caregivers, communities, and policymakers. By
              creating safer environments, educating children and adults about
              injury prevention, and providing access to quality healthcare, we
              can significantly reduce the incidence and impact of childhood
              injuries.
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    </Layout>
  );
};

export default AsthmaManagement;
