import {
  BLOG_AVATAR01,
  BLOG_AVATAR02,
  BLOG_AVATAR03,
  BLOG_AVATAR04,
  BLOG_AVATAR05,
  BLOG_AVATAR06,
  BLOG_IMG01,
  BLOG_IMG02,
  BLOG_IMG03,
  H2_BLOG_IMG01,
  H2_BLOG_IMG02,
  H2_BLOG_IMG03,
  H3_BLOG_IMG01,
  H3_BLOG_IMG02,
  H3_BLOG_IMG03,
  H3_BLOG_IMG04,
  H3_BLOG_IMG05,
  H3_BLOG_IMG06,
  H4_BLOG_IMG01,
  H4_BLOG_IMG02,
  H4_BLOG_IMG03,
  RC_POST01,
  RC_POST02,
  RC_POST03,
  RC_POST04,
} from "../lib/assets";
import IMG01 from "../assets/img/images/aa22.jpg";
import IMG02 from "../assets/img/images/aa23.jpg";
import IMG03 from "../assets/img/images/aa24.jpg";

export const BLOGS_LIST_TWO = [
  {
    id: 1,
    href: "/blog-details",
    src: IMG01,
    src2: IMG01,
    src3: IMG01,
    src4: BLOG_IMG01,
    tag: "Development",
    tag2: "Pediatric",
    tag3: "Development",
    tagHref: "/blog-details",
    title: "Managing Childhood Asthma: Tips for Parents",
    subtitle:
      "Everything you need to start building area atching presence for your business.",
    avatar: BLOG_AVATAR01,
    author: "Kat Doven",
    author2: "Dr. Sanjeev Kumar",
    createdAt: "20 Feb, 2023",
  },
  {
    id: 2,
    href: "/blog-details",
    src: H2_BLOG_IMG02,
    src2: H3_BLOG_IMG02,
    src3: IMG02,
    src4: BLOG_IMG02,
    tag: "Business",
    tag2: "Pediatric",
    tag3: "Development",
    tagHref: "/blog-details",
    title: "Recognizing and Addressing ADHD Symptoms Early",
    subtitle:
      "Everything you need to start building area atching presence for your business.",
    avatar: BLOG_AVATAR01,
    author: "Kat Doven",
    author2: "Dr. Sanjeev Kumar",
    createdAt: "22 Jan, 2023",
  },
  {
    id: 3,
    href: "/blog-details",
    src: H2_BLOG_IMG03,
    src2: H3_BLOG_IMG03,
    src3: IMG03,
    src4: BLOG_IMG03,
    tag: "Tax Advisory",
    tag2: "Pediatric",
    tag3: "Development",
    tagHref: "/blog-details",
    title: "The Importance of Regular Pediatric Check-ups",
    subtitle:
      "Everything you need to start building area atching presence for your business.",
    avatar: BLOG_AVATAR01,
    author: "Kat Doven",
    author2: "Dr. Sanjeev Kumar",
    createdAt: "12 Aug, 2023",
  },
];

export const BLOG_CATEGORIES = [
  {
    label: "Business",
    value: "02",
  },
  {
    label: "Consulting",
    value: "08",
  },
  {
    label: "Corporate",
    value: "05",
  },
  {
    label: "Design",
    value: "02",
  },
  {
    label: "Fashion",
    value: "11",
  },
  {
    label: "Marketing",
    value: "12",
  },
];

export const RECENT_BLOGS = [
  {
    id: 1,
    createdAt: "20 Feb, 2023",
    href: "/blog-details",
    src: "../photo/aa23.jpg",
    title: "Managing Childhood Asthma: Tips for Parents",
  },
  {
    id: 2,
    createdAt: "22 Jan, 2023",
    href: "/blog-details",
    src: "../photo/aa24.avif",
    title: "Recognizing and Addressing ADHD Symptoms Early",
  },
  {
    id: 3,
    createdAt: "12 Aug, 2023",
    href: "/blog-details",
    src: "../photo/aa25.jpg",
    title: "The Importance of Regular Pediatric Check-ups",
  },
];

export const BLOG_TAGS = [
  "Crowns",
  "Habit Correction",
  "Immunizations",
  "Asthma Management",
  "Growth Monitoring",
  "ADHD Evaluation",
  "Nutritional Counseling",
  "Developmental Screenings",
  "Hearing and Vision Tests",
  "Behavioral and Mental Health Services",
];

export const BLOG_PAGE_LIST = [
  {
    id: 1,
    createdAt: "22 Jan, 2023",
    title: "Child Health",
    subtitle:
      "Child health is a critical aspect of overall well-being. It encompasses a wide range of physical, mental, and...",
    tag: "Our Services",
    author: "Kat Doven",
    src: "../photo/aa38.jpg",
    authorSrc: BLOG_AVATAR01,
    href: "/child-health",
    tagHref: "/blog",
  },
  {
    id: 2,
    createdAt: "22 Jan, 2023",
    title: "Fitness In Children",
    subtitle:
      "Fitness is a cornerstone of child health, offering numerous benefits beyond physical well-being. Regular exercise...",
    tag: "Our Services",
    author: "Floyd Miles",
    src: "../photo/aa39.jpg",
    authorSrc: BLOG_AVATAR02,
    href: "/fitness-in-children",
    tagHref: "/blog",
  },
  {
    id: 3,
    createdAt: "22 Jan, 2023",
    title: "Immunizations",
    subtitle:
      "Immunizations, Also Known As Vaccinations, Play A Vital Role In Safeguarding Children's Health By Providing...",
    tag: "Our Services",
    author: "Bessie Cooper",
    src: "../photo/aa5.jpg",
    authorSrc: BLOG_AVATAR03,
    href: "/immunizations",
    tagHref: "/blog",
  },
  {
    id: 4,
    createdAt: "22 Jan, 2023",
    title: "Injury In Children",
    subtitle:
      "Injuries are a common occurrence in childhood, often resulting from a child's natural curiosity and exploratory...",
    tag: "Our Services",
    author: "Devon Lane",
    src: "../photo/aa41.jpg",
    authorSrc: BLOG_AVATAR04,
    href: "/injury-in-children",
    tagHref: "/blog",
  },
  {
    id: 5,
    createdAt: "22 Jan, 2023",
    title: "Growth Monitoring",
    subtitle:
      "Growth Monitoring In Children Is An Essential Practice In Pediatric Care That Involves Regularly Assessing A Child's...",
    tag: "Our Services",
    author: "Annette Black",
    src: "../photo/aa9.jpg",
    authorSrc: BLOG_AVATAR05,
    href: "/growth-monitoring",
    tagHref: "/blog",
  },
  {
    id: 6,
    createdAt: "22 Jan, 2023",
    title: "Common Illness in Children",
    subtitle:
      "Childhood is often accompanied by a series of minor illnesses that are part of the growing process. Understanding...",
    tag: "Our Services",
    author: "Eleanor Pena",
    src: "../photo/aa43.jpg",
    authorSrc: BLOG_AVATAR06,
    href: "/common-illness-in-children",
    tagHref: "/blog",
  },
  {
    id: 7,
    createdAt: "22 Jan, 2023",
    title: "Nutritional Counseling",
    subtitle:
      "Nutritional Counseling For Children Is A Vital Service Aimed At Promoting Healthy Eating Habits And Addressing...",
    tag: "Our Services",
    author: "Eleanor Pena",
    src: "../photo/aa13.jpg",
    authorSrc: BLOG_AVATAR06,
    href: "/nutritional-counseling",
    tagHref: "/blog",
  },
  {
    id: 8,
    createdAt: "22 Jan, 2023",
    title: "Developmental Screenings",
    subtitle:
      "Developmental Screenings Are Essential Evaluations In Pediatric Healthcare Designed To Assess A Child's Growth...",
    tag: "Our Services",
    author: "Eleanor Pena",
    src: "../photo/aa15.jpg",
    authorSrc: BLOG_AVATAR06,
    href: "/developmental-screenings",
    tagHref: "/blog",
  },
  {
    id: 9,
    createdAt: "22 Jan, 2023",
    title: "New Born Care",
    subtitle:
      "The arrival of a newborn is a joyous and transformative experience, but it also comes with a steep learning curve....",
    tag: "Our Services",
    author: "Eleanor Pena",
    src: "../photo/aa45.jpg",
    authorSrc: BLOG_AVATAR06,
    href: "/new-born-care",
    tagHref: "/blog",
  },
  {
    id: 10,
    createdAt: "22 Jan, 2023",
    title: "Travel Medicine",
    subtitle:
      "Travel medicine for children is a specialized area focused on protecting the health and well-being of young...",
    tag: "Our Services",
    author: "Eleanor Pena",
    src: "../photo/aa47.jpg",
    authorSrc: BLOG_AVATAR06,
    href: "/travel-medicine",
    tagHref: "/blog",
  },
];
