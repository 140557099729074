import React from "react";

export const ServicesDetailsFaq = () => {
  return (
    <div style={{ width: "100%" }} className="accordion-wrap-three">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Why is early medical care important for children?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p style={{ color: "white" }}>
                Early medical care helps in identifying and addressing health
                issues before they become serious. Regular check-ups ensure that
                children meet developmental milestones and receive vaccinations,
                which are crucial for their overall health and well-being.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              How does pediatrics differ from general medicine?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p style={{ color: "white" }}>
                Pediatrics specializes in the health of infants, children, and
                adolescents, focusing on their unique physical, emotional, and
                developmental needs. Pediatricians are trained to manage
                conditions specific to younger patients, providing tailored care
                that evolves with the child’s growth.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Interdum et fames ac ante ipsum
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p style={{ color: "white" }}>
                when an unknown printer took a galley of type and scrambled it
                to make a type specimen bookhas a not only five centuries, but
                also the leap into electronic typesetting, remaining essentially
                unchan galley of type and scrambled it to make a type specimen
                book.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
