import {
  H2_TEAM_IMG01,
  H2_TEAM_IMG02,
  H2_TEAM_IMG03,
  H2_TEAM_IMG04,
  H3_TEAM_IMG01,
  H3_TEAM_IMG02,
  H3_TEAM_IMG03,
  H3_TEAM_IMG04,
  H4_TEAM_IMG01,
  H4_TEAM_IMG02,
  H4_TEAM_IMG03,
  H4_TEAM_IMG04,
  H5_TEAM_IMG01,
  H5_TEAM_IMG02,
  H5_TEAM_IMG03,
  H5_TEAM_IMG04,
  TEAM_IMG01,
  TEAM_IMG02,
  TEAM_IMG03,
  TEAM_IMG04,
} from "../lib/assets";

export const TEAM_LIST_TWO = [
  {
    id: 1,
    href: "/team-details",
    src: H2_TEAM_IMG01,
    title: "Brooklyn Simmons",
    designation: "Finance Advisor",
  },
  {
    id: 2,
    href: "/team-details",
    src: H2_TEAM_IMG02,
    title: "Jenny Wilson",
    designation: "Finance Advisor",
  },
  {
    id: 3,
    href: "/team-details",
    src: H2_TEAM_IMG03,
    title: "Wade Warren",
    designation: "Finance Advisor",
  },
  {
    id: 4,
    href: "/team-details",
    src: H2_TEAM_IMG04,
    title: "Marvin McKinney",
    designation: "Finance Advisor",
  },
];

export const TEAM_LIST_THREE = [
  {
    id: 1,
    href: "/team-details",
    src: H3_TEAM_IMG01,
    title: "Brooklyn Simmons",
    designation: "Finance Advisor",
  },
  {
    id: 2,
    href: "/team-details",
    src: H3_TEAM_IMG02,
    title: "Jenny Wilson",
    designation: "Finance Advisor",
  },
  {
    id: 3,
    href: "/team-details",
    src: H3_TEAM_IMG03,
    title: "Wade Warren",
    designation: "Finance Advisor",
  },
  {
    id: 4,
    href: "/team-details",
    src: H3_TEAM_IMG04,
    title: "Marvin McKinney",
    designation: "Finance Advisor",
  },
];

export const TEAM_LIST_FOUR = [
  {
    id: 1,
    href: "/team-details",
    src: H4_TEAM_IMG01,
    title: "Brooklyn Simmons",
    designation: "Finance Advisor",
  },
  {
    id: 2,
    href: "/team-details",
    src: H4_TEAM_IMG02,
    title: "Jenny Wilson",
    designation: "Finance Advisor",
  },
  {
    id: 3,
    href: "/team-details",
    src: H4_TEAM_IMG03,
    title: "Wade Warren",
    designation: "Finance Advisor",
  },
  {
    id: 4,
    href: "/team-details",
    src: H4_TEAM_IMG04,
    title: "Marvin McKinney",
    designation: "Finance Advisor",
  },
];

export const TEAM_LIST_FIVE = [
  {
    id: 1,
    href: "/team-details",
    src: H5_TEAM_IMG01,
    title: "Brooklyn Simmons",
    designation: "Finance Advisor",
  },
  {
    id: 2,
    href: "/team-details",
    src: H5_TEAM_IMG02,
    title: "Jenny Wilson",
    designation: "Finance Advisor",
  },
  {
    id: 3,
    href: "/team-details",
    src: H5_TEAM_IMG03,
    title: "Wade Warren",
    designation: "Finance Advisor",
  },
  {
    id: 4,
    href: "/team-details",
    src: H5_TEAM_IMG04,
    title: "Marvin McKinney",
    designation: "Finance Advisor",
  },
];

export const TEAM_LIST_ONE = [
  {
    id: 1,
    href: "/team-details",
    src: TEAM_IMG01,
    title: "Brooklyn Simmons",
    designation: "Finance Advisor",
  },
  {
    id: 2,
    href: "/team-details",
    src: TEAM_IMG02,
    title: "Jenny Wilson",
    designation: "Finance Advisor",
  },
  {
    id: 3,
    href: "/team-details",
    src: TEAM_IMG03,
    title: "Wade Warren",
    designation: "Finance Advisor",
  },
  {
    id: 4,
    href: "/team-details",
    src: TEAM_IMG04,
    title: "Marvin McKinney",
    designation: "Finance Advisor",
  },
];
