import {
  H4_SERVICES_IMG01,
  H4_SERVICES_IMG02,
  H4_SERVICES_IMG03,
  H4_SERVICES_IMG04,
  SERVICES_IMG01,
  SERVICES_IMG02,
  SERVICES_IMG03,
  SERVICES_ITEM_SHAPE,
} from "../lib/assets";
import IMG01 from "../assets/img/images/aa17.jpeg";
import IMG02 from "../assets/img/images/aa18.jpg";
import IMG03 from "../assets/img/images/aa19.jpg";
import IMG04 from "../assets/img/images/aa20.jpg";
import H2_SERVICES_IMG01 from "../assets/img/images/aa26.jpg";
import H2_SERVICES_IMG02 from "../assets/img/images/aa27.jpg";
import H2_SERVICES_IMG03 from "../assets/img/images/aa28.jpg";
import H2_SERVICES_IMG04 from "../assets/img/images/aa29.jpg";

export const SERVICES_TWO_LIST = [
  {
    id: 1,
    src: H2_SERVICES_IMG01,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-layers",
    href: "/immunizations",
    title: "Immunizations",
    subtitle: "Immunizations Involve Administering Vaccines...",
  },
  {
    id: 2,
    src: "../photo/aa55.jpg",
    icon: "flaticon-healthcare",
    srcShape: SERVICES_ITEM_SHAPE,
    href: "/child-health",
    title: "Child Health",
    subtitle: "Child health is a critical aspect of overll well...",
  },
  {
    id: 3,
    src: H2_SERVICES_IMG03,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-layers",
    href: "/growth-monitoring",
    title: "Growth Monitoring",
    subtitle: "Growth Monitoring Involves Regularly Assessing...",
  },
  {
    id: 4,
    src: "../photo/aa51.jpg",
    icon: "flaticon-healthcare",
    href: "/injury-in-children",
    title: "Injury In Children",
    subtitle: "Injuries are a common occurrence in childhood,...",
  },
];

export const SERVICES_THREE_LIST = [
  {
    id: 1,
    src: IMG01,
    icon: "flaticon-healthcare",
    href: "/immunizations",
    title: "Immunizations",
    subtitle: "Immunizations involve administering vaccines...",
  },
  {
    id: 2,
    src: "../photo/aa49.avif",
    icon: "flaticon-healthcare",
    href: "/child-health",
    title: "Child Health",
    subtitle: "Child health is a critical aspect of overll well...",
  },
  {
    id: 3,
    src: IMG03,
    icon: "flaticon-layers",
    href: "/growth-monitoring",
    title: "Growth Monitoring",
    subtitle: "Growth Monitoring Involves Regularly Assessing...",
  },
  {
    id: 4,
    src: "../photo/aa50.jpg",
    icon: "flaticon-healthcare",
    href: "/injury-in-children",
    title: "Injury In Children",
    subtitle: "Injuries are a common occurrence in childhood,...",
  },
];

export const SERVICES_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-business-presentation",
    href: "/services-details",
    title: "Marketing Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 2,
    icon: "flaticon-rocket",
    href: "/services-details",
    title: "SEO Consultancy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 3,
    icon: "flaticon-mission",
    href: "/services-details",
    title: "Target Audience",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
];

export const SERVICES_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-briefcase",
    href: "/services-details",
    src: SERVICES_IMG01,
    title: "Business Analysis",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 2,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG02,
    title: "Tax Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 3,
    icon: "flaticon-money",
    href: "/services-details",
    src: SERVICES_IMG03,
    title: "Financial Advice",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 4,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG02,
    title: "Tax Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
];
