import React from "react";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";
import { CHECK_ICON, SD_VIDEO_IMG, SERVICES_DETAILS01 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { Layout } from "../../layouts/Layout";

const HearingAndVisionTests = () => {
  return (
    <Layout
      breadcrumb={"New Born Care"}
      title={"New Born Care"}
      backgroundImageUrl={"../../photo/aa46.jpg"}
    >
      {/* services-details-area */}
      <ServicesDetailsWrapper hideContact hideTitle>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src="../photo/aa45.jpg" alt="" />
          </div>

          <div className="services-details-content">
            <h2 className="title">New Born Care</h2>
            <div style={{ marginBottom: "14px" }}>
              The arrival of a newborn is a joyous and transformative
              experience, but it also comes with a steep learning curve. Caring
              for a newborn requires patience, dedication, and a deep
              understanding of their unique needs. Establishing a nurturing and
              safe environment is essential for their physical, emotional, and
              cognitive development.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Feeding is a fundamental aspect of newborn care. Breastfeeding is
              often encouraged for its nutritional and immunological benefits,
              but formula feeding is a viable alternative for mothers unable or
              choosing not to breastfeed. Establishing a consistent feeding
              schedule is crucial for the baby's growth and development. Sleep
              is equally important, as newborns spend most of their time
              sleeping. Creating a safe sleep environment is vital to prevent
              sudden infant death syndrome (SIDS). Babies should always sleep on
              their backs on a firm, flat sleep surface.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Maintaining good hygiene is essential for a newborn's health.
              Gentle bathing, umbilical cord care, and nail trimming are
              important tasks. Frequent diaper changes and keeping the baby's
              environment clean help prevent infections. Regular check-ups with
              a pediatrician are vital for monitoring the baby's growth,
              development, and overall well-being. Immunizations are crucial for
              protecting the baby from preventable diseases.
            </div>

            <div style={{ marginBottom: "14px" }}>
              Bonding with your newborn is a magical experience. Skin-to-skin
              contact, talking, singing, and cuddling help foster a strong
              parent-child bond. Responding to your baby's cues and providing
              comfort are essential for their emotional well-being. Trust your
              instincts and seek support from healthcare professionals and other
              parents for guidance and reassurance.
            </div>

            <div style={{ marginBottom: "14px" }}>
              The journey of newborn care is filled with challenges and rewards.
              By providing your baby with love, care, and attention, you are
              laying the foundation for a healthy and happy future.
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    </Layout>
  );
};

export default HearingAndVisionTests;
