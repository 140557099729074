import { TESTI_AVATAR01, TESTI_AVATAR02 } from "../lib/assets";

export const TESTIMONIAL_LIST_TWO = [
  {
    id: 1,
    text: "Dr. Sanjeev Kumar has been an incredible pediatrician for our family. His 13 years of experience and caring approach make us feel confident in his treatment for our child.",
    src: TESTI_AVATAR01,
    title: "Mr.Robey Alexa",
    designation: "CEO, Gerow Agency",
  },
  {
    id: 2,
    text: "We trust Dr. Kumar completely with our children's health. His expertise and genuine concern for their well-being have made a significant difference in their care and development.",
    src: TESTI_AVATAR02,
    title: "Mr.Robey Alexa",
    designation: "CEO, Gerow Agency",
  },
  {
    id: 3,
    text: "Dr. Sanjeev Kumar is a highly skilled and compassionate pediatrician. His thorough consultations and accurate diagnoses have consistently provided the best care for our little ones.",
    src: TESTI_AVATAR01,
    title: "Mr.Robey Alexa",
    designation: "CEO, Gerow Agency",
  },
];
