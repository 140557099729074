import React from "react";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";
import { CHECK_ICON, SD_VIDEO_IMG, SERVICES_DETAILS01 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { Layout } from "../../layouts/Layout";

const NutritionalCounseling = () => {
  return (
    <Layout
      breadcrumb={"Growth & Nutrition"}
      title={"Growth & Nutrition"}
      backgroundImageUrl={"../../photo/aa14.jpg"}
    >
      {/* services-details-area */}
      <ServicesDetailsWrapper hideContact hideTitle>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src="../photo/aa13.jpg" alt="" />
          </div>

          <div className="services-details-content">
            <h2 className="title">Growth & Nutrition</h2>
            <div style={{ marginBottom: "14px" }}>
              Nutritional counseling for children is a vital service aimed at
              promoting healthy eating habits and addressing dietary issues that
              can affect a child's growth and development. This service involves
              a thorough assessment of a child's nutritional needs and creating
              tailored dietary plans to ensure they receive a balanced and
              adequate intake of nutrients. Pediatricians, dietitians, and
              nutritionists work closely with families to educate them about
              proper nutrition and to address specific concerns such as obesity,
              malnutrition, food allergies, and eating disorders.
            </div>
            <div style={{ marginBottom: "14px" }}>
              One of the primary goals of nutritional counseling is to ensure
              that children are consuming a diet that supports their rapid
              growth and development. During childhood, the body requires a
              variety of nutrients in sufficient quantities to support bone
              growth, brain development, and overall physical health.
              Nutritional counseling helps parents understand the importance of
              providing balanced meals that include a variety of fruits,
              vegetables, whole grains, proteins, and healthy fats. Counselors
              also educate families on portion sizes and the importance of
              regular meal times.
            </div>
            <div style={{ marginBottom: "14px" }}>
              For children struggling with obesity, nutritional counseling
              focuses on developing healthy eating habits and increasing
              physical activity to achieve a healthy weight. This involves
              identifying and modifying behaviors that contribute to excessive
              weight gain, such as high consumption of sugary beverages,
              frequent snacking on unhealthy foods, and sedentary lifestyles.
              Dietitians work with families to create realistic and sustainable
              dietary changes, emphasizing the importance of making healthy food
              choices and incorporating more physical activity into daily
              routines.
            </div>
            <div style={{ marginBottom: "14px" }}>
              In cases of malnutrition or specific dietary deficiencies,
              nutritional counseling provides targeted interventions to address
              these issues. This may involve recommending supplements or
              fortified foods to ensure the child receives necessary vitamins
              and minerals. For children with food allergies or intolerances,
              dietitians help families identify safe and nutritious alternatives
              to avoid triggering foods while still maintaining a balanced diet.
              Additionally, nutritional counseling can support children with
              eating disorders by working with mental health professionals to
              develop strategies for healthy eating behaviors and improving the
              child's relationship with food.
            </div>
            <div style={{ marginBottom: "14px" }}>
              In conclusion, nutritional counseling is a crucial component of
              pediatric healthcare that ensures children receive the necessary
              nutrients for their growth and development. By educating families
              on healthy eating habits, addressing specific dietary concerns,
              and promoting overall wellness, nutritional counseling helps set
              the foundation for a lifetime of healthy eating. Collaborative
              efforts between healthcare providers and families are essential to
              successfully implementing dietary changes and fostering an
              environment that supports the child's nutritional needs.
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
    </Layout>
  );
};

export default NutritionalCounseling;
